Vue.component('projects-search-component', {
    props: ['searchQuery'],
    data() {
        return {
            searchQueryStr: this.searchQuery,
            typing: null,
            debounce: null
        };
    },
    created() {
    },
    mounted() {
    },
    destroy() {
    },
    destroyed() {
    },
    methods: {
        setSearch() {
            this.$emit('search', this.searchQueryStr);
        },
        debounceSearch() {
            clearTimeout(this.debounce);
            this.debounce = setTimeout(() => {
                this.typing = null;
                this.setSearch();
            }, 600)
        }
    }
});
