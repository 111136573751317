Vue.component("language-select", {
    data: function () {
        return {
            menuExpanded: false
        }
    },
    mounted: function () {

    },
    methods: {
        updateMenuStates(){
            if(this.menuExpanded) {
                this.$emit('update','language');
            } else {
                this.$emit('update','');
            }
        },
        toggleMenu(){
           this.menuExpanded = !this.menuExpanded;
           this.updateMenuStates();
        },
        closeMenu(){
            this.menuExpanded = false;
            this.updateMenuStates();
        }
    },
});
