Vue.component("card-component", {
    data: function () {
        return {
            test: 2
        }
    },
    mounted: function () {

    },
    methods: {
        triggerLink() {
            if (this.$refs.link) {
                this.$refs.link.click();
            }
        }
    },
});
