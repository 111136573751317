Mixins.viewport = {
    methods: {
        isInViewport(elem) {
            let bounding = elem.getBoundingClientRect();
            return (
                bounding.top >= 0 &&
                bounding.left >= 0 &&
                bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
        },
        isElementXPercentInViewport(el, percentVisible) {
            let
                rect = el.getBoundingClientRect(),
                windowHeight = (window.innerHeight || document.documentElement.clientHeight);

            return !(
                Math.floor(100 - (((rect.top >= 0 ? 0 : rect.top) / +-(rect.height / 1)) * 100)) < percentVisible ||
                Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) < percentVisible
            )
        }
    }
}
