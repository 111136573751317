Mixins.cookies =  {
    data: function () {
        return {
            cookieConsent: {
                necessary: false,
                preferences: false,
                statistics: false,
                marketing: false,
            }
        }
    },
    created() {
        let cookie =  document.cookie.match('(^|;)\\s*CookieConsent\\s*=\\s*([^;]+)').pop().replace(/{|}/gi, '') || false
        if (cookie) {
            cookie.split('%2C').forEach((item) => {
                let arr = item.split(':')
                this.cookieConsent.necessary = arr[0] === 'necessary' ? (arr[1] === "true") : this.cookieConsent.necessary
                this.cookieConsent.preferences = arr[0] === 'preferences' ? (arr[1] === "true") : this.cookieConsent.preferences
                this.cookieConsent.statistics = arr[0] === 'statistics' ? (arr[1] === "true") : this.cookieConsent.statistics
                this.cookieConsent.marketing = arr[0] === 'marketing' ? (arr[1] === "true") : this.cookieConsent.marketing
            })
        }
    }
};
