Vue.component("portal-selection-component", {
    data: function () {
        return {
            hoverOn: ""
        }
    },
    computed: {
        selection: function(){

            return {
                'portal-selection--select-local': this.hoverOn.indexOf('local') > -1,
                'portal-selection--select-global': this.hoverOn.indexOf('global') > -1,
            }
        }
    },
    mounted: function () {

    },
    methods: {},
});
