Vue.component('projects-filter-dropdown-component', {
    props: ['selectedFilter', 'selectedRegion', 'bus'],
    data() {
        return {
            expanded: false,
            defaultName: true,
            childName: '',
            childActive: false,
        }
    },
    mounted() {
        let that = this;
        this.bus.$on('filter', function(){
            that.defaultName = true;
            that.childActive = false;
            console.log('bus emit');
        })
    },
    methods: {
        setFilter(value, label) {
            this.bus.$emit('filter');

            console.log('set');
            if(typeof label != "undefined"){
                this.childName = label;
                this.defaultName = false;
                this.childActive = true;
            }

            this.$parent.setFilter(value);
        },
        getDropdownState() {
            return {
                'filter__dropdown--open': this.expanded
            };
        },
        toggleFilterDropdown() {
            this.expanded = !this.expanded;
        },
        isVisible(local, global) {
            return (this.selectedRegion == 'local' && local) || (this.selectedRegion == 'global' && global);
        }
    }
});
