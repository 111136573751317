/**
 * Component for rendering a list of Model items with infinite scroll
 *
 * New content will be loaded by XHR and will be passed on as raw HTML
 * The raw HTML blocks are compiled als Vue code with working components (via html-component)
 *
 */
Mixins.lazyList = {
    props: ['queryParameters'],
    data: function () {
        return {
            // The hash which the page was initially loaded with
            initialFragment: window.location.hash,
            // The current hash
            currentFragment: null,
            // Is the component still initializing ?
            isInitializing: true,
            // Is the autoloading limited ?
            autoLoadingLimited: false,
            // Should we show the paging generated by the back-end
            showOriginalPaging: false,
            // Is the XHR currently handling a request
            xhrIsLoading: false,
            // What was the last XHR response
            xhrResponse: null,
            // Did the last XHR request return an error ?
            xhrHasError: false,
            // Are we currently keeping track of the visible item ?
            checkVisibleEnabled: false,
            // A small timeout container, to prevent flooding of the browser
            checkVisibleItemTimeout: false,
            // Should we hide the original HTML
            hideOriginalHtml: false,
            // Should we hide the load-more button on the original pageload ?
            hideLoadMoreButton: true,
            // Should we show a message that there are no results ?
            noResults: false,
            // Container for storing the HTML content that was added later on via XHR
            htmlResults: [],
            // Is bLazy beeing used ?
            useBlazy: true
        }
    },
    created() {
        // First strip of any hash in the initial fragment
        if(this.initialFragment.indexOf('#') == 0) {
            this.initialFragment = this.initialFragment.substr(1);
        }

        this.hideLoadMoreButton = this.queryParameters.hideLoadMoreButton;

        // Check if there is a page requested higher then the first one
        let page = parseInt(this.getQueryStringParameter(window.location.href, this.queryParameters.page));
        if (!isNaN(page) && page > 0 && page != 1) {
            // Request the entire content from page 1 to $page
            this.getPage(page, true, true);
        } else {
            // Set state tot beeing initialized
            this.isInitializing = false;

            // Enable the visible item tracker
            this.checkVisibleEnabled = true;
        }
    },
    mounted() {
        // Attach the on scroll event
        window.addEventListener('scroll', this.onScroll);

        // Check for room in the viewport to load some more results
        this.checkViewport();
    },
    destroyed() {
        // Remove the on scroll event
        window.removeEventListener('scroll', this.onScroll);
    },
    updated() {
        if(typeof(AOS) != 'undefined') {
            AOS.refreshHard();
        }
    },
    computed: {
        /**
         * Return the current page, based on either the last meta data or else default
         *
         * @returns {*}
         */
        currentPage() {
            if (this.xhrResponse != null && this.xhrResponse.hasOwnProperty('meta')) {
                return this.xhrResponse.meta.current_page;
            }

            return 1;
        },
        lastPage() {
            console.log('123')
            if (this.xhrResponse != null && this.xhrResponse.hasOwnProperty('meta')) {
                return this.xhrResponse.meta.last_page;
            }

            return null;
        }
    },
    methods: {
        /**
         * Start an XHR requst to the server to retrieve results
         *
         * @param page          The page number
         * @param complete      Should the back-end return the complete contents of page 1 to $page
         * @param toFragment    When the request is finished and processed, should the browser scroll to the active hash (if any)
         */
        getPage(page, complete = false, toFragment = false) {

            // Skip request when we have reached the last page
            if(this.lastPage !== null && page > this.lastPage) {
                return;
            }

            // Set the XHR loading state to true
            this.xhrIsLoading = true;

            // Create the desired URL
            let url = this.appendQueryParameters(window.location.href, page, complete);

            // Start the request
            axios({
                method: 'get',
                url: url,
            }).then((response) => {
                // Store the XHR response
                this.xhrResponse = response.data;

                // Update the current page parameter
                this.updateCurrentPageParameter();

                // Append or replace hte HTML content
                if(this.xhrResponse.hasOwnProperty('html') && this.xhrResponse.html != '') {
                    if(complete) {
                        //this.$refs.items.innerHTML = '';
                        this.hideOriginalHtml = true;
                    }

                    // Create a new wrapper (div) which contains the HTML
                    let wrapper = document.createElement('div');
                    wrapper.innerHTML = this.xhrResponse.html;

                    // Push the HTML into the components array
                    this.htmlResults.push(wrapper.outerHTML);

                    // Refresh AOS
                    AOS.refreshHard();
                }

                // Set the initialization state to false
                this.isInitializing = false;

                // Set the XHR loading state to false
                this.xhrIsLoading = false;

                // Reset noResults-message if it was set to true before
                this.noResults = false;

                // Because we've manually placed HTML, it's better to wait until the next update cycle to make sure everything is in the DOM
                this.$nextTick(() => {

                    setTimeout(function() {
                        if(this.useBlazy) {
                            // Tell Blazy to revalidate the images
                            this.$root.bLazy.revalidate();
                        }
                    }.bind(this), 1);

                    // Should we scroll to a specific item ?
                    if(toFragment && this.initialFragment != '' && this.initialFragment != null) {

                        // Disable the visible item tracker temporary and clear any existing timeout
                        // Otherwise the onScoll event will pick up the scroll from moveTo and then it will break
                        this.checkVisibleEnabled = false;
                        if(this.checkVisibleItemTimeout) {
                            clearTimeout(this.checkVisibleItemTimeout);
                        }

                        // Scroll to the desired item
                        this.$root.MoveToInstance.move(document.getElementById(this.initialFragment), {
                            callback: function() {
                                // When finished, enable the visible item tracker again
                                this.checkVisibleEnabled = true;
                            }.bind(this)
                        });
                    } else {

                        // Enable the visible item tracker
                        this.checkVisibleEnabled = true;
                    }

                    // Check if we have room to store som more results
                    this.checkViewport();

                    AOS.refreshHard();
                });

            }).catch((error) => {
                // An error is received when there are no more pages left

                // Set the initialization state to false
                this.isInitializing = false;

                // Set the XHR loading state to false
                this.xhrIsLoading = false;

                // Set the XHR error state to true
                this.xhrHasError = true;

                // Display 'no results' message
                this.noResults = true;
            });
        },
        /**
         * Append needed parameters to the query string
         *
         * @param url
         * @param page
         * @param complete
         * @returns {*}
         */
        appendQueryParameters(url, page, complete = false) {
            url = this.updateQueryStringParameter(url, this.queryParameters.page, page);
            url = this.updateQueryStringParameter(url, this.queryParameters.complete, (complete ? 1 : 0));

            return url;
        },
        /**
         * Fucntion triggered when the user scroll the browser
         */
        onScroll() {
            // Check which item is currently at the first visible position in the viewport
            this.checkVisibleItem();

            // Check if we have room to store som more results
            this.checkViewport();
        },
        /**
         * Check if there is room in the viewport to load more results or if the user has scrolled to the bottom of the items (infinity scroll)
         */
        checkViewport() {
            // Only trigger the request when there is no request happening and we didn't receive any errors
            if(!this.xhrIsLoading && !this.xhrHasError) {
                let overviewBoundary = this.$el.getBoundingClientRect();

                this.autoLoadingLimit = this.lastPage;

                console.log(this.autoLoadingLimit, this.currentPage)

                if ((!this.autoLoadingLimited || this.currentPage <= this.autoLoadingLimit) && window.innerHeight > (overviewBoundary.height + overviewBoundary.top)) {
                    this.getPage(this.currentPage+1);
                }
            }
        },
        /**
         * Update the browsers address bar/history with the current page number
         */
        updateCurrentPageParameter() {
            let url = window.location.href;

            url = this.updateQueryStringParameter(url, this.queryParameters.page, this.currentPage);

            this.hideLoadMoreButton = this.currentPage === this.lastPage;

            window.history.replaceState({}, document.title, url);
        },
        /**
         * Check which (first!) item is completely in sight inside the viewport, and append it's hash to the address bar/history
         */
        checkVisibleItem() {

            if(this.checkVisibleItemTimeout) {
                clearTimeout(this.checkVisibleItemTimeout);
            }

            if(!this.xhrIsLoading && this.checkVisibleEnabled) {
                this.checkVisibleItemTimeout = setTimeout(function () {
                    let items = this.$refs.items.children;

                    if (items.length > 0) {
                        for (var i = 0; i < items.length; i++) {
                            if (this.isInViewport(items[i])) {
                                let itemFragment = items[i].getAttribute('id');
                                if (this.currentFragment != itemFragment) {

                                    let url = this.updateHash(window.location.href, itemFragment);

                                    window.history.replaceState({}, document.title, url);
                                    this.currentFragment = this.itemFragment;
                                }
                                break;
                            }
                        }
                    }
                }.bind(this), 1);
            }
        },
    },
};
