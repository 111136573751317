Vue.component('slider-component', {
    props: [ 'images', 'options' ],
    data: function () {
        return {
            instance: null,
            defaults: {
                pageDots: true,
                prevNextButtons: false,
                cellAlign: 'center',
                wrapAround: true
            },
            previousAvailable: null,
            nextAvailable: null,
        }
    },
    created() {

    },
    mounted() {
        this.init();
    },
    destroyed() {

    },
    computed: {
    },
    methods: {
        init() {
            let options = Object.assign(this.defaults, this.options);
            this.instance = new Flickity(this.$refs.slider, options);

            this.instance.on('ready', this.setButtonAvailability);
            this.instance.on('change', this.setButtonAvailability);
            this.instance.on('scroll', this.setButtonAvailability);
            this.instance.on('settle', this.setButtonAvailability);

            this.setButtonAvailability();
        },
        previous() {
            this.instance.previous();
        },
        next() {
            this.instance.next();
        },
        setButtonAvailability() {

            this.previousAvailable = false;
            this.nextAvailable = false;

            if(this.instance != null) {
                let wrapArround = (this.instance != null && this.instance.options.hasOwnProperty('wrapAround') && this.instance.options.wrapAround);
                if(wrapArround) {
                    this.previousAvailable = true;
                    this.nextAvailable = true;
                }

                let currentSlide = this.instance.selectedIndex;
                let totalSlides = this.instance.slides.length;

                if(currentSlide > 0) {
                    this.previousAvailable = true;
                }

                if(currentSlide < (totalSlides - 1)) {
                    this.nextAvailable = true;
                }
            }
        }
    },
});
