Mixins.queryString = {
    methods: {
        updateHash(uri, hash) {
            // First strip off the current hash
            let hashIndex = uri.indexOf('#');
            uri = (hashIndex > 0 ? uri.substr(0, hashIndex) : uri);

            // Now send it back with the new hash
            return uri + '#' + hash;
        },
        updateQueryStringParameter(uri, key, value) {
            // First strip off the hash, so we can append it later on
            let hashIndex = uri.indexOf('#');
            let hash = (hashIndex > 0 ? uri.substr(hashIndex) : '');
            uri = (hashIndex > 0 ? uri.substr(0, hashIndex) : uri);

            // Find the occurance
            const re = new RegExp("([?&])" + key + "=[^#]*?(&|$)", "i");
            const separator = uri.indexOf('?') !== -1 ? "&" : "?";

            // If occured, then replacem otherwise append
            if (uri.match(re)) {
                return uri.replace(re, '$1' + key + "=" + value + '$2') + hash;
            }
            else {
                return uri + separator + key + "=" + value + hash;
            }
        },
        removeQueryStringParameter(url, parameter) {

            // First strip off the hash, so we can append it later on
            let hashIndex = url.indexOf('#');
            let hash = (hashIndex > 0 ? url.substr(hashIndex) : '');
            url = (hashIndex > 0 ? url.substr(0, hashIndex) : url);

            //prefer to use l.search if you have a location/link object
            var urlparts = url.split('?');
            if (urlparts.length >= 2) {

                var prefix = encodeURIComponent(parameter) + '=';
                var pars = urlparts[1].split(/[&;]/g);

                //reverse iteration as may be destructive
                for (var i = pars.length; i-- > 0;) {
                    //idiom for string.startsWith
                    if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                        pars.splice(i, 1);
                    }
                }

                return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '') + hash;
            }
            return url + hash;
        },
        getQueryStringParameter(url, name) {
            if (!url) url = window.location.href;
            name = name.replace(/[\[\]]/g, '\\$&');
            var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
                results = regex.exec(url);
            if (!results) return null;
            if (!results[2]) return '';
            return decodeURIComponent(results[2].replace(/\+/g, ' '));
        },
    }
};
