// converted from: https://github.com/maoberlehner/building-a-parallax-scrolling-effect-component-with-vue.git

Vue.component("parallax-container-component", {
    template: `
    <div class="parallax-container">
    <slot/>
    </div>
    `,
    name: "parallax-container-component",

    provide() {
        return {
            parallaxContainer: this.data,
        };
    },
    data() {
        return {
            data: {
                height: 0,
                scrollFactor: 0,
                width: 0,
            },
        };
    },
    mounted() {
        this.calcParallax();

        // We're using a `requestAnimationFrame()`
        // for optimal performance.
        const eventHandler = () => requestAnimationFrame(this.calcParallax);
        window.addEventListener(`resize`, eventHandler);
        window.addEventListener(`scroll`, eventHandler);
        // Remove the scroll handler when the
        // component is destroyed.
        this.$on(`hook:destroyed`, () => {
            window.removeEventListener(`resize`, eventHandler);
            window.removeEventListener(`scroll`, eventHandler);
        });
    },
    methods: {
        calcParallax() {
            const containerRect = this.$el.getBoundingClientRect();

            this.data.height = containerRect.height;
            this.data.width = containerRect.width;

            const viewportOffsetTop = containerRect.top;
            const viewportOffsetBottom = window.innerHeight - viewportOffsetTop;

            this.data.scrollFactor = viewportOffsetBottom / (window.innerHeight + this.data.height);
        },
    },
});

Vue.component("parallax-image", {
    template: `
      <div
    :style="{
      height: \`\${compensatedHeight}px\`,
    }"
    class="parallax-image"
  >
    <parallax-element
      :factor="compensatedFactor"
      :style="{
        paddingTop: \`\${aspectRatio * 100}%\`,
      }"
      class="parallax-image__aspect-ratio-wrap"
    >
      <div
        ref="inside"
        class="parallax-image__aspect-ratio-inside"
      >
        <slot/>
      </div>
    </parallax-element>
  </div>`,
    name: "parallax-image",

    props: {
        factor: {
            default: 0.25,
            type: Number,
        },
        ratio: {
            default: 2 / 3,
            type: Number,
        }
    },
    data() {
        return {
            innerHeight: 0,
        };
    },
    computed: {
        aspectRatio() {
            return this.ratio;
        },
        compensatedFactor() {
            // Because the parallax effect is relative
            // to the containers height and because we
            // shrink the containers height by the given
            // factor, we have to compensate this by
            // increasing the factor.
            return this.factor * 2;
        },
        compensatedHeight() {
            // We want the image to scroll inside of a
            // container to prevent the image scrolling
            // above its sourounding elements. The
            // container must be shrinked by the given
            // factor to make sure we don't have any
            // whitespace when scrolling.
            return this.innerHeight - (this.innerHeight * this.factor);
        },
    },
    mounted() {

        /* use the given aspect to calculate height based on the width */
        this.width = this.$refs.inside.getBoundingClientRect().width;
        this.height = this.width * this.aspectRatio;

        this.setInnerHeight();

        const eventHandler = () => requestAnimationFrame(this.setInnerHeight);
        window.addEventListener(`resize`, eventHandler);
        this.$on(`hook:destroyed`, () => {
            window.removeEventListener(`resize`, eventHandler);
        });
    },
    methods: {
        setInnerHeight() {
            this.innerHeight = this.$refs.inside.getBoundingClientRect().height;
        },
    },
});

Vue.component("parallax-element", {
    name: "parallax-element",

    template: '<div :style="{ transform: `translate3d(0, ${offset}px, 0)`, }" class="parallax-element"><slot/></div>',

    inject: [`parallaxContainer`],
    props: {
        factor: {
            default: 0.25,
            type: Number,
        },
    },
    computed: {
        offset() {
            const {height, scrollFactor} = this.parallaxContainer;
            // The offset is relative to the height of
            // the element. This means, if the factor is
            // 0.5, the element is moved half its height
            // over the scroll distance of the viewport.
            return scrollFactor * height * this.factor;
        },
    },
});
