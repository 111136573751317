Vue.component('projects-list-component', {
    mixins: [ Mixins.queryString, Mixins.viewport, Mixins.lazyList ],
    props: [ 'selectedRegion', 'selectedFilter', 'searchQuery', 'initialState' ],
    data: function () {
        return {
        }
    },
    created() {
        if(!this.initialState) {
            this.reset();
            this.getPage(1, true);
        }
    },
    methods: {
        /**
         * Append needed parameters to the query string
         *
         * @param url
         * @param page
         * @param complete
         * @returns {*}
         */
        appendQueryParameters(url, page, complete = false) {
            url = this.updateQueryStringParameter(url, this.queryParameters.page, page);
            url = this.updateQueryStringParameter(url, this.queryParameters.complete, (complete ? 1 : 0));
            url = this.updateQueryStringParameter(url, this.queryParameters.region, this.selectedRegion);

            if(this.selectedFilter != '') {
                url = this.updateQueryStringParameter(url, this.queryParameters.filter, this.selectedFilter);
            } else {
                url = this.removeQueryStringParameter(url, this.queryParameters.filter);
            }

            if(this.searchQuery != '') {
                url = this.updateQueryStringParameter(url, this.queryParameters.search, this.searchQuery);
            } else {
                url = this.removeQueryStringParameter(url, this.queryParameters.search);
            }

            return url;


            AOS.refreshHard();
        },
        reset() {
            this.currentFragment = null;
            this.xhrResponse = null;
            this.xhrHasError = false;
            this.xhrIsLoading = true;
            this.hideOriginalHtml = true;

            this.htmlResults = [];

            AOS.refreshHard();
        },
        loadMore() {
            this.getPage(this.currentPage+1);
        }
    },
    watch: {
        selectedFilter() {
            this.reset();
            this.getPage(1, true);
            AOS.refreshHard();
        },
        selectedRegion() {
            this.reset();
            this.getPage(1, true);
            AOS.refreshHard();
        },
        searchQuery() {
            this.reset();
            this.getPage(1, true);
            AOS.refreshHard();
        }
    }
});
