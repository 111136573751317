Vue.component('projects-index-component', {
    mixins: [ Mixins.queryString ],
    props: [ 'queryParameters' ],
    data() {
        return {
            isInitializing: true,
            supportedViewStates: [ 'list', 'map' ],
            viewState: 'list',
            supportedRegions: [ 'local', 'global' ],
            region: 'local',
            selectedFilter: '',
            searchQuery: '',
            initialState: true,
        }
    },
    created() {
        // Check if there is a requested viewState
        let viewState = this.getQueryStringParameter(window.location.href, this.queryParameters.view);
        if (viewState !== null && viewState != '' && this.supportedViewStates.indexOf(viewState) >= 0) {
            this.viewState = viewState;
        }

        // Check if there is a requested region
        let region = this.getQueryStringParameter(window.location.href, this.queryParameters.region);
        if (region !== null && region != '' && this.supportedRegions.indexOf(region) >= 0) {
            this.region = region;
        }

        let selectedFilter = this.getQueryStringParameter(window.location.href, this.queryParameters.filter);
        if (selectedFilter !== null && selectedFilter != '') {
            this.selectedFilter = selectedFilter;
        }

        let searchQuery = this.getQueryStringParameter(window.location.href, this.queryParameters.search);
        if (searchQuery !== null && searchQuery != '') {
            this.searchQuery = searchQuery;
        }
    },
    mounted() {
        this.isInitializing = false;
    },
    destroy() {

    },
    destroyed() {

    },
    methods: {
        setViewState(viewState) {
            this.initialState = false;
            this.viewState = viewState;
            this.updateCurrentPageParameter();
        },
        setFilter(value) {
            this.selectedFilter = value;
            this.updateCurrentPageParameter();
        },
        setSearch(value) {
            this.searchQuery = value;
            this.updateCurrentPageParameter();
        },
        toggleRegion() {
            this.initialState = false;
            this.region = this.supportedRegions[(this.supportedRegions.indexOf(this.region) + 1) % this.supportedRegions.length];
            this.selectedFilter = '';
            this.updateCurrentPageParameter();
        },
        isViewState(viewState) {
            return this.viewState == viewState;
        },
        isRegion(region) {
            return this.region == region;
        },
        /**
         * Update the browsers address bar/history with the parameters
         */
        updateCurrentPageParameter() {
            let url = window.location.href;

            url = this.updateQueryStringParameter(url, this.queryParameters.view, this.viewState);
            url = this.updateQueryStringParameter(url, this.queryParameters.region, this.region);

            if(this.selectedFilter != '') {
                url = this.updateQueryStringParameter(url, this.queryParameters.filter, this.selectedFilter);
            } else {
                url = this.removeQueryStringParameter(url, this.queryParameters.filter);
            }

            if(this.searchQuery != '') {
                url = this.updateQueryStringParameter(url, this.queryParameters.search, this.searchQuery);
            } else {
                url = this.removeQueryStringParameter(url, this.queryParameters.search);
            }

            url = this.removeQueryStringParameter(url, this.queryParameters.page);

            window.history.replaceState({}, document.title, url);
        },

    }
});
