Vue.component('projects-filter-component', {
    mixins: [Mixins.queryString],
    props: ['queryParameters', 'selectedFilter', 'selectedRegion'],
    data() {
        return {
            filterState: false,
            bus: new Vue(),
        };
    },
    created() {
    },
    mounted() {
    },
    destroy() {
    },
    destroyed() {
    },
    methods: {
        setFilter(value) {
            let newValue = (value || '');
            if(newValue == this.selectedFilter) {
                newValue = '';
            }
            this.$emit('filter', newValue)
        },
        toggleFilter() {
            this.filterState = !this.filterState;
        },
        isVisible(local, global) {
            return (this.selectedRegion == 'local' && local) || (this.selectedRegion == 'global' && global);
        }
    }
});
