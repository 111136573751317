
Vue.component("parallax-mouse-container-component", {
    template: `
    <div
:style="{height: height}"
class="parallax-mouse-container">
    <slot/>
    </div>`,
    name: `parallax-mouse-container-component`,
    props: {
        ratio:
            {
                default: 16 / 9,
                type: Number,
            },
    },
    provide() {
        return {
            parallaxContainer: this.data,
        };
    },
    data() {
        return {
            data: {
                height: 0,
                width: 0,
                displacement: { x: 0, y: 0 },
            },
        };
    },
    computed: {
        height() {
            return `${this.data.height}px`;
        },
    },
    mounted() {
        this.calcSize();
        this.parallaxMobile();

        const moveEventHandler = e => requestAnimationFrame(() => this.calcDisplacement(e));
        const resizeEventHandler = e => requestAnimationFrame(() => this.calcSize(e));

        window.addEventListener(`mousemove`, moveEventHandler);
        window.addEventListener(`resize`, resizeEventHandler);
        // this.$el.addEventListener(`mousemove`, eventHandler);

        this.$on(`hook:destroyed`, () => {
            window.removeEventListener(`mousemove`, moveEventHandler);
            window.removeEventListener(`resize`, resizeEventHandler);
        });

        AOS.refreshHard();
    },
    methods: {
        calcSize() {
            const containerRect = this.$el.getBoundingClientRect();

            this.data.height = containerRect.width / this.ratio;
            this.data.width = containerRect.width;
        },
        calcDisplacement(e) {
            if (e) {
                const center = { x: window.innerWidth / 2, y: window.innerHeight / 2 };
                const displacement = { x: e.x - center.x, y: e.y - center.y };
                this.data.displacement.x = displacement.x / center.x;
                this.data.displacement.y = displacement.y / center.y;
            }
        },
        parallaxMobile() {
            if (window.innerWidth < 1200) {
                window.addEventListener('scroll', scrollParallax);

                function scrollParallax() {
                    var windowScroll = this.scrollY;
                    var windowTop = windowScroll + window.innerHeight;
                    var $wrapper = document.getElementsByClassName('parallax-mouse-container')[0];
                    var wrapperTop = $wrapper.offsetParent.offsetTop;
                    var check = elementInViewport($wrapper);
                    var calculated = windowTop - wrapperTop;

                    if (check) {
                        if (calculated > 0) {
                            var $firstLayer = document.getElementsByClassName('parallax-layer')[0];
                            $firstLayer.style.top = '0px';
                            $firstLayer.style.transform = 'translate3d(0px, -25px, 0px)';

                            var $secondLayer = document.getElementsByClassName('parallax-layer')[1];
                            $secondLayer.style.top = calculated / 45 + 'px';
                            $secondLayer.style.transform = 'translate3d(0px, -50px, 0px)';

                            var $thirdLayer = document.getElementsByClassName('parallax-layer')[2];
                            $thirdLayer.style.top = calculated / 15 + 'px';
                            $thirdLayer.style.transform = 'translate3d(0px, -75px, 0px)';
                        }
                    }
                }
            }
        },
    },
});

Vue.component("parallax-layer", {
    template: '<div :style="{height: height + \'px\', width: width + \'px\', top: top + \'%\', left: left + \'%\', transform: \'translate3d(\' + offsetX + \'px,\' + offsetY + \'px, 0)\'}" class="parallax-layer"> <slot></slot></div>',
    name: 'parallax-layer',
    inject: ['parallaxContainer'],
    props: {
        parallaxFactor: {
            default: 0,
            type: Number,
        },
        zoomFactor: {
            default: 1.25,
            type: Number,
        },
        images: {
            default: () => [],
            type: Array,
        },
    },
    data() {
        return {
            innerHeight: 0,
        };
    },
    computed: {
        height(){
            return this.parallaxContainer.height * this.zoomFactor;
        },
        width(){
            return this.parallaxContainer.width * this.zoomFactor;
        },

        top(){
            const dy = (this.height - this.parallaxContainer.height ) / 2;
            return -100 / this.parallaxContainer.height * dy;
        },

        left(){
            const dx = (this.width - this.parallaxContainer.width ) / 2;
            return -100 / this.parallaxContainer.width * dx;
        },
        offsetX() {
            const { displacement } = this.parallaxContainer;
            return displacement.x * this.parallaxFactor;
        },
        offsetY() {
            const { displacement } = this.parallaxContainer;
            return displacement.y * this.parallaxFactor;
        },

    },
    mounted() {

    },
    methods: {

    },
});
