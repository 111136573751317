if(typeof Mixins == 'undefined'){
    window.Mixins = {};
}
Mixins.debug = {
    data: function () {
        return {
            showDebug: false,
        }
    },
    methods: {
        debug() {
            if(this.showDebug) {
                console.debug.apply(null, arguments);
            }
        }
    }
}
