/**
 * @see https://github.com/xkjyeah/vue-google-maps/
 * @see http://xkjyeah.github.io/vue-google-maps/
 * @see http://xkjyeah.github.io/vue-google-maps/autoapi.html
 */
Vue.component('projects-map-component', {
    mixins: [ Mixins.queryString ],
    props: [ 'route', 'initialCenter', 'selectedRegion', 'selectedFilter', 'queryParameters' ],
    components: {
        'gmap-cluster': VueGoogleMaps.Cluster
    },
    data: function () {
        return {
            // Is the component still initializing ?
            isInitializing: true,
            // Is the XHR currently handling a request
            xhrIsLoading: false,
            // What was the last XHR response
            xhrResponse: null,
            // Did the last XHR request return an error ?
            xhrHasError: false,
            // Has a marker been selected ?
            markerSelected: null,
            // The index of the selected markers inside the markers array
            markerSelectedIndex: null,
            // The Google API object of the selected marker
            markerSelectedObject: null,
            // Is the infowindow currently opened ?
            infoWindowOpened: false,
        }
    },
    created() {
    },
    mounted() {
        this.$refs.mapRef.$mapPromise.then((map) => {
            this.getMarkers();
        });
    },
    methods: {
        getMarkers() {
            // Set the XHR loading state to true
            this.xhrIsLoading = true;

            let url = this.route;

            url = this.updateQueryStringParameter(url, this.queryParameters.region, this.selectedRegion);

            if(this.selectedFilter != '') {
                url = this.updateQueryStringParameter(url, this.queryParameters.filter, this.selectedFilter);
            } else {
                url = this.removeQueryStringParameter(url, this.queryParameters.filter);
            }

            // Start the request
            axios({
                method: 'get',
                url: url,
            }).then((response) => {
                // Store the XHR response
                this.xhrResponse = response.data;

                // Set the initialization state to false
                this.isInitializing = false;

                // Set the XHR loading state to false
                this.xhrIsLoading = false;

                this.fitBounds();
            }).catch((error) => {
                // An error is recieved when there are no more pages left

                // Set the initialization state to false
                this.isInitializing = false;

                // Set the XHR loading state to false
                this.xhrIsLoading = false;

                // Set the XHR error state to true
                this.xhrHasError = true;
            });
        },
        onMarkerClick(marker, index) {
            this.$refs.mapRef.panTo(marker.position);

            this.markerSelected = marker;
            this.markerSelectedObject = this.$refs[index + 1][0].$markerObject

            if (this.markerSelectedIndex == index) {
                this.infoWindowOpened = !this.infoWindowOpened;
            } else {
                this.markerSelectedIndex = index;
                this.infoWindowOpened = true;
            }
        },
        onCloseClick() {
            this.resetMarker();
        },
        resetMarker() {
            this.markerSelected = null;
            this.markerSelectedObject = null;
            this.markerSelectedIndex = null;
            this.infoWindowOpened = false;
        },
        fitBounds() {
            const bounds = new google.maps.LatLngBounds()
            for (let m of this.markers) {
                bounds.extend(m.position)
            }
            this.$refs.mapRef.$mapPromise.then((map) => {

                const bounds = new google.maps.LatLngBounds()
                for (let m of this.markers) {
                    bounds.extend(m.position)
                }

                var originalMaxZoom = map.maxZoom;
                map.setOptions({maxZoom: 9});
                map.fitBounds(bounds);
                map.setOptions({maxZoom: originalMaxZoom});

                google.maps.event.addListenerOnce(map, 'bounds_changed', () => {
                    this.$forceUpdate();
                });

            });
        }

    }
    ,
    watch: {
        selectedFilter() {
            this.xhrResponse = null;
            this.resetMarker();
            this.getMarkers();
        },
        selectedRegion() {
            this.xhrResponse = null;
            this.resetMarker();
            this.getMarkers();
        }
    },
    computed: {
        markers() {
            if(this.xhrResponse && this.xhrResponse.hasOwnProperty('data')) {
                return this.xhrResponse.data;
            }
        },
        center() {
            return this.initialCenter;
        }
    }

});
