/**
 * @see https://github.com/xkjyeah/vue-google-maps/issues/208
 * @see https://github.com/atmist/snazzy-info-window
 */
Vue.component('projects-infowindow-component', {
    props: ['marker', 'markerObject', 'opened'],
    data () {
        return {
            infoWindow: null
        }
    },
    mounted () {
        this.createInfoWindow()
    },

    methods: {
        createInfoWindow () {
            if(!this.marker) {
                return false;
            }

            const SnazzyInfoWindow = require('snazzy-info-window');
            this.infoWindow = new SnazzyInfoWindow({
                marker: this.markerObject,
                content: this.$refs.wrapper,
                showCloseButton: false,
                placement: 'top',
                border: false,
                pointer: false,
                panOnOpen: true,
                padding: '0',
                shadow: false,
                offset: {
                    top: '-20px',
                    left: '1px'
                },
                callbacks: {
                    beforeClose: () => {
                        this.$emit('close')
                    }
                }
            })
            this.infoWindow.open()
        },
        close () {
            this.infoWindow.close()
        }
    },

    watch: {
        marker () {
            if(this.infoWindow) {
                this.infoWindow.destroy()
                this.infoWindow = null;
            }

            this.createInfoWindow()
        }
    },

    beforeDestroy () {
        if(this.infoWindow) {
            this.infoWindow.destroy()
        }
    },

    template: '<div ref="wrapper"><slot></slot></div>'
})
