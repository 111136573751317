if (!Object.entries)
    Object.entries = function( obj ){
        var ownProps = Object.keys( obj ),
            i = ownProps.length,
            resArray = new Array(i); // preallocate the Array
        while (i--)
            resArray[i] = [ownProps[i], obj[ownProps[i]]];

        return resArray;
    };

(function() {
    Vue.use(VeeValidate, {
        locale: document.documentElement.lang,
        events: 'blur|submit|change',
        classes: true,
        invalidateFalse: true,
        classNames: {
            invalid: 'input--invalid',
            valid: 'input--valid'
        },
        dictionary: {
            nl: {
                messages: {
                    _default: (field) => `Dit veld is niet correct ingevuld.`,
                    after: (field, [target, inclusion]) => `Dit veld moet groter ${inclusion ? 'of gelijk aan ' : ''} ${target}.`,
                    alpha_dash: (field) => `Dit veld mag alleen alfanumerieke karakters, strepen en onderstrepingstekenen bevatten.`,
                    alpha_num: (field) => `Dit veld mag alleen alfanumerieke karakters bevatten.`,
                    alpha_spaces: (field) => `Dit veld mag alleen alfanumerieke karakters en spaties bevatten.`,
                    alpha: (field) => `Dit veld mag alleen alfabetische karakters bevatten.`,
                    before: (field, [target, inclusion]) => `De waarde van dit veld moet kleiner ${inclusion ? 'of gelijk aan' : ''} ${target} zijn.`,
                    between: (field, [min, max]) => `De waarde van dit veld moet tussen ${min} en ${max} zijn.`,
                    confirmed: (field) => `Dit bevestigingsveld komt niet overeen.`,
                    credit_card: (field) => `Dit veld is ongeldig.`,
                    date_between: (field, [min, max]) => `De datum moet tussen ${min} en ${max} zijn.`,
                    date_format: (field, [format]) => `Dit veld moet het volgende formaat hebben: ${format}.`,
                    decimal: (field, [decimals = '*'] = []) => `Dit veld mag alleen numerieke, en${!decimals || decimals === '*' ? ' ' : decimals}decimale nummers bevatten.`,
                    digits: (field, [length]) => `Dit veld moet ${length} nummers bevatten.`,
                    dimensions: (field, [width, height]) => `De dimensies voor dit veld moet ${width} pixels breed en ${height} pixels hoog zijn.`,
                    email: (field) => `Dit veld moet een geldig e-mailadres bevatten.`,
                    ext: (field) => `Dit veld moet een correct bestand bevatten.`,
                    image: (field) => `Dit veld moet een afbeelding bevatten.`,
                    included: (field) => `Dit veld moet een geldige waarde bevatten.`,
                    integer: (field) => `Dit veld moet een nummer zijn.`,
                    ip: (field) => `Dit veld moet een veilig ip adres zijn.`,
                    length: (field, [length, max]) => {
                        if (max) {
                            return `Dit veld moet minimaal ${length} karakters en maximaal ${max} karakters bevatten.`;
                        }

                        return `Dit veld moet minimaal ${length} karakters lang zijn.`;
                    },
                    max: (field, [length]) => `Dit veld mag niet meer karakters bevatten dan ${length}.`,
                    max_value: (field, [max]) => `Dit veld mag een maximale waarde hebben van ${max}.`,
                    mimes: (field) => `Dit veld moet Dit juiste type bestand bevatten.`,
                    min: (field, [length]) => `Dit veld moet minimaal ${length} karakters zijn.`,
                    min_value: (field, [min]) => `Dit veld moet een minimale waarde hebben van ${min}.`,
                    excluded: (field) => `Dit veld moet een geldige waarde bevatten`,
                    numeric: (field) => `Dit veld mag alleen numerieke karakters bevatten.`,
                    regex: (field) => `Dit veld is niet correct ingevoerd.`,
                    required: (field) => `Dit veld is verplicht.`,
                    size: (field, [size]) => `De bestandsgrootte van dit veld mag niet groter zijn dan ${formatFileSize(size)}.`,
                    url: (field) => `Dit veld moet een valide URL zijn.`
                }
            },
            en: {
                messages: {
                    _default: (field) => `This field is not filled in correctly.`,
                    after: (field, [target, inclusion]) => `This field must be larger than ${inclusion ? 'or equal to ' : ''} ${target}.`,
                    alpha_dash: (field) => `This field can only contain alphanumeric characters, dashes and underscores.`,
                    alpha_num: (field) => `This field may only contain alphanumeric characters.`,
                    alpha_spaces: (field) => `This field can only contain alphanumeric characters and spaces.`,
                    alpha: (field) => `This field can only contain alphabetic characters.`,
                    before: (field, [target, inclusion]) => `The value of this field must be less than ${inclusion ? 'or equal to ' : ''} ${target} zijn.`,
                    between: (field, [min, max]) => `The value of this field must be between ${min} and ${max}.`,
                    confirmed: (field) => `This confirmation field does not match.`,
                    credit_card: (field) => `This field is invalid.`,
                    date_between: (field, [min, max]) => `The date must be between ${min} and ${max}.`,
                    date_format: (field, [format]) => `This field must have the following format: ${format}.`,
                    decimal: (field, [decimals = '*'] = []) => `This field may only be numeric, and ${!decimals || decimals === '*' ? ' ' : decimals} contain decimal numbers.`,
                    digits: (field, [length]) => `This field must contain ${length} numbers.`,
                    dimensions: (field, [width, height]) => `The dimensions for this field must be ${width} pixels wide and ${height} pixels high.`,
                    email: (field) => `This field must contain a valid email address.`,
                    ext: (field) => `This field must contain a correct file.`,
                    image: (field) => `This field must contain an image.`,
                    included: (field) => `This field must contain a valid value.`,
                    integer: (field) => `This field must be a number.`,
                    ip: (field) => `This field must be a secure IP address.`,
                    length: (field, [length, max]) => {
                        if (max) {
                            return `This field must contain at least ${length} characters and at most ${max} characters.`;
                        }

                        return `This field must be at least ${length} characters long.`;
                    },
                    max: (field, [length]) => `This field cannot contain more characters than ${length}.`,
                    max_value: (field, [max]) => `This field can have a maximum value of ${max}.`,
                    mimes: (field) => `This field must contain This correct type of file.`,
                    min: (field, [length]) => `This field must be at least ${length} characters.`,
                    min_value: (field, [min]) => `This field must have a minimum value of ${min}.`,
                    excluded: (field) => `This field must contain a valid value`,
                    numeric: (field) => `This field can only contain numeric characters.`,
                    regex: (field) => `This field has not been entered correctly.`,
                    required: (field) => `This field is required.`,
                    size: (field, [size]) => `The file size of this field cannot exceed ${formatFileSize(size)}.`,
                    url: (field) => `This field must be a valid URL.`
                }
            },
            de: {
                messages: {
                    _default: (field) => `Dieses Feld ist nicht richtig ausgefüllt`,
                    after: (field, [target, inclusion]) => `Dieses Feld muss größer sein als ${inclusion ? 'oder gleich ' : ''} ${target}.`,
                    alpha_dash: (field) => `This field can only contain alphanumeric characters, dashes and underscores.`,
                    alpha_num: (field) => `Dieses Feld darf nur alphanumerische Zeichen, Bindestriche und Unterstriche enthalten.`,
                    alpha_spaces: (field) => `Dieses Feld darf nur alphanumerische Zeichen und Leerzeichen enthalten.`,
                    alpha: (field) => `Dieses Feld darf nur alphabetische Zeichen enthalten.`,
                    before: (field, [target, inclusion]) => `Der Wert dieses Feldes muss kleiner als sein ${inclusion ? 'oder gleich ' : ''} ${target}.`,
                    between: (field, [min, max]) => `Der Wert dieses Feldes muss zwischen liegen ${min} und ${max}.`,
                    confirmed: (field) => `Dieses Bestätigungsfeld stimmt nicht überein.`,
                    credit_card: (field) => `Dieses Feld ist ungültig.`,
                    date_between: (field, [min, max]) => `Das Datum muss zwischen liegen ${min} und ${max}.`,
                    date_format: (field, [format]) => `Dieses Feld muss das folgende Format haben: ${format}.`,
                    decimal: (field, [decimals = '*'] = []) => `Dieses Feld darf nur numerisch sein und ${!decimals || decimals === '*' ? ' ' : decimals} Dezimalzahlen enthalten.`,
                    digits: (field, [length]) => `Dieses Feld muss enthalten ${length} Zahlen.`,
                    dimensions: (field, [width, height]) => `Die Abmessungen für dieses Feld müssen sein ${width} Pixel breit und ${height} Pixel hoch.`,
                    email: (field) => `Dieses Feld muss eine gültige E-Mail-Adresse enthalten.`,
                    ext: (field) => `Dieses Feld muss eine korrekte Datei enthalten.`,
                    image: (field) => `Dieses Feld muss ein Bild enthalten.`,
                    included: (field) => `Dieses Feld muss einen gültigen Wert enthalten.`,
                    integer: (field) => `Dieses Feld muss eine Zahl sein.`,
                    ip: (field) => `Dieses Feld muss eine sichere IP-Adresse sein.`,
                    length: (field, [length, max]) => {
                        if (max) {
                            return `Dieses Feld muss mindestens enthalten ${length} Zeichen und höchstens ${max} Zeichen.`;
                        }

                        return `Dieses Feld muss mindestens enthalten ${length} Zeichen lang.`;
                    },
                    max: (field, [length]) => `Dieses Feld darf nicht mehr Zeichen als enthalten ${length}.`,
                    max_value: (field, [max]) => `Dieses Feld kann einen Maximalwert von haben ${max}.`,
                    mimes: (field) => `Dieses Feld muss diesen korrekten Dateityp enthalten.`,
                    min: (field, [length]) => `Dieses Feld muss mindestens sein ${length} Zeichen.`,
                    min_value: (field, [min]) => `Dieses Feld muss einen Mindestwert von haben ${min}.`,
                    excluded: (field) => `Dieses Feld muss einen gültigen Wert enthalten`,
                    numeric: (field) => `Dieses Feld darf nur numerische Zeichen enthalten.`,
                    regex: (field) => `Dieses Feld wurde nicht korrekt eingegeben.`,
                    size: (field, [size]) => `Die Dateigröße dieses Feldes darf nicht überschritten werden ${formatFileSize(size)}.`,
                    url: (field) => `Dieses Feld muss eine gültige URL sein.`,
                    required: (field) => `Dieses Feld muss ausgefüllt werden!`,
                }
            },
            fr: {
                messages: {
                    _default: (field) => `Ce champ n'est pas rempli correctement`,
                    after: (field, [target, inclusion]) => `Ce champ doit être plus grand que ${inclusion ? 'ou égal à ' : ''} ${target}.`,
                    alpha_dash: (field) => `Ce champ ne peut contenir que des caractères alphanumériques, des tirets et des traits de soulignement.`,
                    alpha_num: (field) => `Ce champ ne peut contenir que des caractères alphanumériques.`,
                    alpha_spaces: (field) => `Ce champ ne peut contenir que des caractères alphanumériques et des espaces.`,
                    alpha: (field) => `Ce champ ne peut contenir que des caractères alphabétiques.`,
                    before: (field, [target, inclusion]) => `La valeur de ce champ doit être inférieure à ${inclusion ? 'ou égal à ' : ''} ${target}.`,
                    between: (field, [min, max]) => `La valeur de ce champ doit être comprise entre ${min} et ${max}.`,
                    confirmed: (field) => `Ce champ de confirmation ne correspond pas.`,
                    credit_card: (field) => `Ce champ n'est pas valide.`,
                    date_between: (field, [min, max]) => `La date doit être comprise entre ${min} et ${max}.`,
                    date_format: (field, [format]) => `Ce champ doit avoir le format suivant: ${format}.`,
                    decimal: (field, [decimals = '*'] = []) => `Ce champ ne peut être que numérique, et ${!decimals || decimals === '*' ? ' ' : decimals} contiennent des nombres décimaux.`,
                    digits: (field, [length]) => `Ce champ doit contenir des nombres ${length}.`,
                    dimensions: (field, [width, height]) => `Les dimensions de ce champ doivent être de ${width} pixels de large et de ${height} pixels de hautd.`,
                    email: (field) => `Ce champ doit contenir une adresse e-mail valide.`,
                    ext: (field) => `Ce champ doit contenir un fichier correct.`,
                    image: (field) => `Ce champ doit contenir une image.`,
                    included: (field) => `Ce champ doit contenir une valeur valide.`,
                    integer: (field) => `Ce champ doit être un nombre.`,
                    ip: (field) => `Ce champ doit être une adresse IP sécurisée.`,
                    length: (field, [length, max]) => {
                        if (max) {
                            return `Ce champ doit contenir au moins ${length} caractères et au plus ${max} caractères.`;
                        }

                        return `Ce champ doit contenir au moins ${length} caractères.`;
                    },
                    max: (field, [length]) => `Ce champ ne peut pas contenir plus de caractères que ${length}.`,
                    max_value: (field, [max]) => `Ce champ peut avoir une valeur maximale de ${max}.`,
                    mimes: (field) => `Ce champ doit contenir ce type de fichier correct.`,
                    min: (field, [length]) => `Ce champ doit contenir au moins ${length} caractères.`,
                    min_value: (field, [min]) => `Ce champ doit avoir une valeur minimale de ${min}.`,
                    excluded: (field) => `Ce champ doit contenir une valeur valide`,
                    numeric: (field) => `Ce champ ne peut contenir que des caractères numériques.`,
                    regex: (field) => `Ce champ n'a pas été renseigné correctement.`,
                    size: (field, [size]) => `La taille du fichier de ce champ ne peut pas dépasser ${formatFileSize(size)}.`,
                    url: (field) => `Ce champ doit être une URL valide.`,
                    required: (field) => `Ce champ n'est pas rempli correctement.`,
                }
            }
        }
    });

    Vue.use(VueGoogleMaps, {
        load: {
            language: document.documentElement.lang,
            key: 'AIzaSyABzjyW56Q1hMVE7PJnlDZtxXHr_Bv-PNU',
        },
        installComponents: true
    });
})();

let Global;

let FadeTransition = Barba.BaseTransition.extend({
    start: function () {
        // As soon the loading is finished and the old page is faded out, let's fade the new page
        Promise
            .all([this.newContainerLoading, this.fadeOut()])
            .then(this.fadeIn.bind(this));
    },

    fadeOut: function () {
        return new Promise((resolve, reject) => {
            document.querySelector('.barba-bg').classList.add('barba-bg--visible');
            document.body.classList.remove('body--loaded');
            window.setTimeout(function () {
                resolve();
            }, 150);
        });
    },

    fadeIn: function () {
        (this.oldContainer).style.display = 'none';

        window.scrollTo(0, 0);

        document.querySelector('.barba-bg').classList.remove('barba-bg--visible');

        setTimeout(function () {
            document.body.classList.add('body--loaded');
        }, 250);

        this.done();
    }
});

Barba.Pjax.getTransition = function () {
    return FadeTransition;
};

Barba.Dispatcher.on('newPageReady', (currentStatus, oldStatus, container, newPageRawHTML) => {
    let regexp = /\<body.*\sclass=["'](.+?)["'].*\>/gi,
        match = regexp.exec(newPageRawHTML);
    if (!match || !match[1]) return;
    document.body.setAttribute('class', match[1]);
});

Barba.Dispatcher.on("transitionCompleted", function () {
    Global = new Vue({
        el: document.getElementById("app"),
        store,
        components: {
            'vue-recaptcha': VueRecaptcha
        },
        data() {
            let headerHeight = 0;
            if (document.querySelector('header')) {
                headerHeight = document.querySelector('header').clientHeight;
            }
            return {
                bLazy: null,
                MoveToInstance: new MoveTo({
                    tolerance: headerHeight,
                    duration: 800
                }),
                hasCaptcha: false,
                formMessage: '<div class="form-message form-message--active %status%">' +
                    '   <div class="form-message__content">%message%</div>' +
                    '   <span class="form-message__icon form-message__icon--success">' +
                    '       <svg viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M1671 566q0 40-28 68l-724 724-136 136q-28 28-68 28t-68-28l-136-136-362-362q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 295 656-657q28-28 68-28t68 28l136 136q28 28 28 68z"/></svg>' +
                    '   </span>' +
                    '   <span class="form-message__icon form-message__icon--error">' +
                    '       <svg width="32" height="32" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M1088 1248v224q0 26-19 45t-45 19h-256q-26 0-45-19t-19-45v-224q0-26 19-45t45-19h256q26 0 45 19t19 45zm30-1056l-28 768q-1 26-20.5 45t-45.5 19h-256q-26 0-45.5-19t-20.5-45l-28-768q-1-26 17.5-45t44.5-19h320q26 0 44.5 19t17.5 45z"/></svg>' +
                    '   </span>' +
                    '</div>',
            }
        },
        methods: {
            Init() {
                this.Blazy();
                this.AOS();
                this.Veevalidate();
                this.Sharer();
            },
            AOS() {
                AOS.init({
                    duration: 600,
                    easing: 'ease-in-out',
                    disableMutationObserver: true,
                    once: true,
                });
            },
            Sharer() {
                Sharer.init();
            },
            Blazy() {
                this.bLazy = new Blazy({
                    breakpoints: [{
                        width: 0,
                        src: 'data-src-small'
                    }, {
                        width: 640,
                        src: 'data-src-medium'
                    }, {
                        width: 1024,
                        src: 'data-src-large'
                    }, {
                        width: 1200,
                        src: 'data-src-xlarge'
                    }, {
                        width: 1440,
                        src: 'data-src-xxlarge'
                    }]
                })
            },
            Veevalidate() {
                switch (document.documentElement.lang) {
                    case 'nl':
                        var message = 'Minimaal 1 van deze velden is verplicht.';
                        break;
                    case 'de':
                        var message = 'Dieses Feld muss ausgefüllt werden!';
                        break;
                    case 'fr':
                        var message = 'Ce champ doit être rempli.';
                        break;
                    default:
                        var message = 'Minimal 1 of the fields are required';
                }

                this.$validator.extend('required_without', {
                    options: {
                        hasTarget: true,
                        computesRequired: true
                    },
                    // getMessage: (field) => `Minimaal 1 van deze velden is verplicht.`,
                    getMessage: (field) => message,
                    validate: function(value, ref) {

                        let refLength = String(ref[0]).trim().length;
                        let valueLength = String(value).trim().length;

                        let isRequired = (!refLength && !valueLength);
                        let isValid = (refLength > 0 || valueLength > 0);

                        return {
                            valid: isValid,
                            data: {
                                required: isRequired
                            }
                        };
                    }
                });
            },
            Axios() {
                // Add a response interceptor
                axios.interceptors.response.use(function (response) {
                    // Do something with response data
                    return response;
                }, function (error) {
                    // Do something with response error
                    // todo error handling
                    return Promise.reject(error);
                });
            },
            submit: function (e) {
                const that = e.target;
                this.$validator.validateAll().then(result => {
                    if (result) {
                        that.classList.add('form--loading');
                        if (!this.hasCaptcha || this.captchaExecuted) {
                            this.submitForm(that);
                        } else {
                            this.$refs.recaptcha.execute();
                        }
                    }
                });
            },
            submitForm(form) {
                const that = this;
                if (form.querySelector('.form-message')) {
                    form.querySelector('.form-message').remove();
                }

                let messageTemplate = this.formMessage;
                let formData = new FormData(form);

                axios({
                    method: 'post',
                    url: form.action,
                    data: formData
                }).then(function (response) {

                    if (response.data.target) {
                        Barba.Pjax.goTo(response.data.target);
                        return;
                    }

                    messageTemplate = messageTemplate.replace(/%message%/, response.data.message);
                    messageTemplate = messageTemplate.replace(/%status%/, 'form-message--success');

                    form.innerHTML = messageTemplate;
                    form.classList.toggle('form--loading');

                    // Sends the event to the Google Analytics property with
                    // tracking ID GA_TRACKING_ID set by the config command in
                    // the global tracking snippet.
                    if (window.gtag !== undefined) {
                        gtag('event', 'Ingevuld', {
                            'event_category': (form.dataset.analytics !== undefined ? form.dataset.analytics : 'Formulierenbeheer')
                        });
                    }

                    this.MoveToInstance.move(document.querySelector('.form-message'));

                }).catch(function (error) {
                    if (error.response !== undefined) {
                        messageTemplate = messageTemplate.replace(/%message%/, error.response.data.message);
                        messageTemplate = messageTemplate.replace(/%status%/, 'form-message--error');

                        form.classList.toggle('form--loading');
                        form.insertAdjacentHTML('afterbegin', messageTemplate);

                        that.MoveToInstance.move(document.querySelector('.form-message'));
                    } else {
                        that.MoveToInstance.move(document.querySelector('.form-message'));
                    }
                });
            },
            onCaptchaVerified: function () {
                this.captchaExecuted = true;
                let form = document.querySelector('.form--loading');
                if (form) {
                    this.submitForm(form);
                }
            },
            SmoothScroll() {
                const that = this;

                document.querySelectorAll('a[href^="#"]').forEach(anchor => {
                    if (anchor.getAttribute('href').length > 1) {
                        anchor.addEventListener('click', function (e) {
                            e.preventDefault();

                            let target = document.querySelector(this.getAttribute('href'));
                            target = target ? target : document.querySelector('[name=' + this.getAttribute('href').slice(1) + ']');
                            that.MoveToInstance.move(target);
                        });

                    }
                });
            },
            ScrollTo(target){
                let elm = document.querySelector(target);
                this.MoveToInstance.move(elm);
            }
        },
        created() {
        },
        mounted() {
            this.SmoothScroll();
            this.Init();
        },
        updated() {
            AOS.refreshHard();
            console.log("Hoi")
        }
    })
});

Vue.directive('click-outside', {
    bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
            // here I check that click was outside the el and his childrens
            if (!(el == event.target || el.contains(event.target))) {
                // and if it did, call method provided in attribute value
                vnode.context[binding.expression](event);
            }
        };
        document.body.addEventListener('click', el.clickOutsideEvent)
    },
    unbind: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
    },
});

Barba.Dispatcher.on('initStateChange', function () {
    if(Global) {
        Global.$destroy();
    }
});

document.addEventListener("DOMContentLoaded", function () {
    document.querySelector('body').classList.add('body--loaded');
    Barba.Pjax.cacheEnabled = false;
    Barba.Pjax.start();
});

function elementInViewport(el) {
    var top = el.offsetTop;
    var left = el.offsetLeft;
    var width = el.offsetWidth;
    var height = el.offsetHeight;

    while(el.offsetParent) {
        el = el.offsetParent;
        top += el.offsetTop;
        left += el.offsetLeft;
    }

    return (
        top < (window.pageYOffset + window.innerHeight) &&
        left < (window.pageXOffset + window.innerWidth) &&
        (top + height) > window.pageYOffset &&
        (left + width) > window.pageXOffset
    );
}

/**
 * Toevoeging voor het correct tracken in analytics
 *
 * @see https://developers.google.com/analytics/devguides/collection/analyticsjs/single-page-applications
 */
Barba.Dispatcher.on('initStateChange', function () {
    if (Barba.HistoryManager.history.length <= 1) {
        return;
    }
    // send statics by Google Analytics(analytics.js) or Google Tag Manager
    if (typeof ga === 'function' && typeof ga.getAll === 'function') {
        var trackers = ga.getAll();
        trackers.forEach(function (tracker) {
            ga(tracker.get('name') + '.set', 'page', location.pathname);
            ga(tracker.get('name') + '.send', 'pageview');
        });
    }
});
