Vue.component("header-component", {
    data: function () {
        return {
            scrolled: false,
            menuState: "",
            searching: false,
            menuExpand: false
        }
    },
    watch: {
        menuExpand (val){
            if(val) {
                bodyScrollLock.disableBodyScroll(this.$refs.scrollLock);
                console.log(this.$refs.scrollLock)
            } else {
                bodyScrollLock.enableBodyScroll(this.$refs.scrollLock);
                console.log(this.$refs.scrollLock)
            }
        },
    },
    mounted () {
        let that = this;
        window.addEventListener('scroll', function () {
            that.watchScrollPosition();
        });

        this.watchScrollPosition();

        bodyScrollLock.clearAllBodyScrollLocks();
    },
    methods: {
        toggleSearch: function() {
            this.searching = !this.searching;
        },
        watchScrollPosition: function () {
            const scrollTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);
        },
        update: function (label) {
            this.menuState = label;
        },
        handleEnter: function(label){
            this.menuState = label;
        },
        handleLeave: function(label){
            this.menuState = label;
        },
        getClasses: function(){
            return {
                'header__gradient--full': this.menuState !== '',
                'header__gradient--navigation': this.menuState === 'navigation',
                'header__gradient--language': this.menuState === 'language',
                'header__gradient--site-select': this.menuState === 'site-select',
            };
        }
    },
});
